<template>
    <div class="InfoCard">
        <div
            class="card click"
            v-for="(item, index) in cardList"
            :key="'infoCard' + index"
            @click="toJump(item.active, item.url)"
        >
            <div class="flex-alc">
                <img :src="item.img" class="icon" alt="" />
                <div class="text">{{ item.name }}</div>
            </div>
            <img src="@/assets/yjt.png" class="img" alt="" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "InfoCard",
        data() {
            return {
                cardList: [
                    {
                        name: "我的师傅",
                        url: "/myInfo/master",
                        img: require("@/assets/sf.png"),
                        active: 2,
                    },
                    {
                        name: "我的徒弟",
                        url: "/myInfo/apprentice",
                        img: require("@/assets/td.png"),
                        active: 3,
                    },
                    {
                        name: "我的证书",
                        url: "/myInfo/certificate",
                        img: require("@/assets/zs.png"),
                        active: 6,
                    },
                ],
            };
        },
        methods: {
            toJump(index, url) {
                window.localStorage.setItem("myNavActive", index);
                this.$store.commit("switchMyNav", index);
                this.$router.push({ path: url });
            },
        },
    };
</script>

<style lang="less" scoped>
.InfoCard {
    display: flex;
    justify-content: space-between;
    .card {
        display: flex;
        justify-content: space-between;
        padding: 25px 20px;
        width: 306px;
        background: #ffffff;
        border-radius: 5px;
        align-items: center;
        .icon {
            width: 40px;
            height: 40px;
        }
        .text {
            font-size: 16px;
            font-weight: 400;
            color: #2d2d2d;
            margin-left: 18px;
        }
        .img {
            width: 19px;
            height: 12px;
        }
    }
}
</style>