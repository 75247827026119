<template>
    <div class="Lecturer">
        <div class="infoCard">
            <div>
                <div class="flex">
                    <img
                        class="head"
                        :src="
                            user.avatar || require('@/assets/defulatAvatar.png')
                        "
                        alt=""
                    />
                    <div class="ml-18 flex-jsc column">
                        <div class="flex-alc">
                            <div class="name">{{ user.name }}</div>
                            <div class="tag">
                                <img
                                    src="@/assets/time.png"
                                    class="timeImg"
                                    alt=""
                                />
                                <div class="time">
                                    在职工龄
                                    {{
                                        user.department_info.working_years ||
                                        "-"
                                    }}年
                                </div>
                            </div>
                        </div>
                        <div class="department">
                            {{ user.department_info.department }}
                            {{
                                user.department_info.position
                                    ? "| " + user.department_info.position
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <img src="" alt="" />
            </div>

            <div class="flex-alc">
                <div class="line"></div>
                <div class="flex-alc column">
                    <div class="rate">{{ score.toFixed(1) }}</div>
                    <div class="comprehensive">综合得分</div>
                </div>
            </div>
        </div>
        <!-- <InfoCard class="mt-17"></InfoCard> -->
        <div class="myClass">
            <div class="text">我的课程</div>
            <div class="flex-alc wrap">
                <Curriculum
                    v-for="(item, index) in curList"
                    :key="'cur' + index"
                    class="mb-20"
                    :curriculum="item"
                    :imgSize="218"
                    :border="6"
                    :class="{ 'mr-17': (index + 1) % 4 != 0 }"
                ></Curriculum>
            </div>
            <div class="flex-jsc mt-32 mb-6">
                <Pagination
                    :pageSize="8"
                    :total="total"
                    @getPage="getPage"
                ></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import InfoCard from "@/components/InfoCard.vue";
    import Curriculum from "@/components/Curriculum.vue";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Lecturer",
        components: {
            Curriculum,
            Pagination,
            InfoCard,
        },
        data() {
            return {
                curList: [],
                total: 0,
                score: 0,
            };
        },

        computed: {
            user: function () {
                return this.$store.state.user;
            },
        },

        mounted() {
            this.getCur();
            window.localStorage.setItem("myNavActive", 4);
            this.$store.commit("switchMyNav", 4);
        },

        methods: {
            getPage(e) {
                this.getCur(e);
            },
            getCur(page = 1) {
                this.$https
                    .get("/api/lecturer/center", { page, limit: 8 })
                    .then((res) => {
                        this.curList = res.data.list || [];
                        this.total = res.data.total_count || 0;
                        this.score = res.data.score || 0;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Lecturer {
    width: 100%;
    .infoCard {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 10px;
        padding: 26px 52px;
        justify-content: space-between;
        .line {
            width: 1px;
            height: 85px;
            border: 1px solid #949494;
            margin-right: 71px;
        }
        .head {
            width: 90px;
            height: 90px;
            border-radius: 100%;
        }
        .name {
            font-size: 24px;
            font-weight: 500;
            color: #2d2d2d;
        }
        .tag {
            background: rgba(0, 0, 0, 0.14);
            border-radius: 11px;
            padding: 2px 9px;
            display: flex;
            align-items: center;
            margin-left: 18px;
            .timeImg {
                width: 11px;
                height: 11px;
                margin-right: 4px;
            }
            .time {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
            }
        }
        .department {
            font-size: 16px;
            font-weight: 400;
            color: #2d2d2d;
            margin-top: 13px;
        }
        .rate {
            font-size: 34px;
            color: #000000;
        }
        .comprehensive {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            margin-top: 25px;
        }
    }
    .myClass {
        padding: 18px 23.5px;
        width: 100%;
        background: white;
        border-radius: 5px;
        margin-top: 19px;
        .text {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            text-align: center;
            margin-bottom: 18px;
        }
    }
}
</style>